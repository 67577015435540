import React, { useCallback, useEffect, useState } from 'react';
import './pc.scss';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import HomePC from '../home/HomePC';
import LogoSvg from '@/assets/logo.svg';
import LogoEnSvg from '@/assets/logo-en.svg';
import LogoWhiteEnSvg from '@/assets/logo-white-en.svg';
import LogoWhiteSvg from '@/assets/logoWhite.svg';
import EmailSvg from '@/assets/email.svg';
import QRCode from '@/assets/QRCode.jpg';
import { Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import qs from 'querystring';
import { useTranslation } from 'react-i18next';
import ExplorePC from '../explore/ExplorePC';

const LayoutPC: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [selected, setSelected] = useState<string>();
  const onChangeLang = useCallback((value) => {
    history.push(`${location.pathname}?lang=${value}`);
  }, []);
  const isActive = useCallback(
    (hash) => {
      return location.hash.indexOf(hash) > -1 ? true : false;
    },
    [location]
  );

  useEffect(() => {
    const lang = (qs.parse(location.search.slice(1))?.lang || 'zh') as string;
    setSelected((value) => {
      if (lang !== value) {
        i18n.changeLanguage(lang);
      }
      return lang;
    });
  }, [location]);

  return (
    <div className="layout pc">
      <header>
        <div className="header-container content-wrapper">
          <span className="logo">
            <Link to={`/?lang=${selected}`}>
              <img src={selected === 'en' ? LogoEnSvg : LogoSvg} />
            </Link>
          </span>
          <div className="nav">
            <Link
              className={isActive('#home') ? 'active' : ''}
              to={`/?lang=${selected}#home`}
              data-hash="home"
            >
              {t('首页')}
            </Link>
            <Link
              className={isActive('#products') ? 'active' : ''}
              to={`/?lang=${selected}#products`}
              data-hash="products"
            >
              {t('产品')}
            </Link>
            <Link
              className={isActive('#solution') ? 'active' : ''}
              to={`/?lang=${selected}#solution`}
              data-hash="solution"
            >
              {t('解决方案')}
            </Link>
            <Link
              className={isActive('#kaiwu') ? 'active' : ''}
              to={`/?lang=${selected}#kaiwu`}
              data-hash="kaiwu"
            >
              {t('BACK 3D')}
            </Link>
            <Link
              className={isActive('#about') ? 'active' : ''}
              to={`/?lang=${selected}#about`}
              data-hash="about"
            >
              {t('关于我们')}
            </Link>
            {/* <Link
              className={isActive('#contact') ? 'active' : ''}
              to={`/?lang=${selected}#contact`}
              data-hash="contact"
            >
              {t('联系我们')}
            </Link> */}
          </div>
          <div className="tools">
            <Select
              value={selected}
              onChange={onChangeLang}
              bordered={false}
              dropdownClassName="lang-select"
              dropdownMatchSelectWidth={115}
              dropdownAlign={{ offset: [0, 0] }}
              suffixIcon={<></>}
              optionLabelProp="label"
            >
              <Select.Option
                key="zh"
                value="zh"
                label={
                  <>
                    中文
                    <DownOutlined
                      style={{ marginLeft: '8px', fontSize: '12px' }}
                    />
                  </>
                }
              >
                中文
              </Select.Option>
              <Select.Option
                key="en"
                value="en"
                label={
                  <>
                    English
                    <DownOutlined
                      style={{ marginLeft: '8px', fontSize: '12px' }}
                    />
                  </>
                }
              >
                English
              </Select.Option>
            </Select>
            <Link className="more-btn" to={`/explore?lang=${selected}`}>
              {t('了解更多')}
            </Link>
          </div>
        </div>
      </header>
      <div className="center">
        <Switch>
          <Route path={'/'} exact={true} component={HomePC} />
          <Route path={'/explore'} exact={true} component={ExplorePC} />
          <Redirect to={'/'} />
        </Switch>
      </div>
      <div className="footer">
        <div className="content-wrapper">
          <section className="contact">
            <div className="col col-1">
              <div className="title">
                <img src={selected === 'en' ? LogoWhiteEnSvg : LogoWhiteSvg} />
              </div>
              <div className="detail">
                {t(
                  '白心通过人工智能(AI), 和大数据(Data)的技术创新，为面向未来的消费品牌和包装包材供应商提供端到端产品设计与供应链服务能力的整体数字化解决方案。'
                )}
              </div>
            </div>
            <div className="col col-2">
              <div className="title">{t('快速入口')}</div>
              <div className="detail">
                <div>
                  <div>
                    <Link to={`/?lang=${selected}#home`}>{t('首页')}</Link>
                  </div>
                  <div>
                    <Link to={`/?lang=${selected}#products`}>{t('产品')}</Link>
                  </div>
                  <div>
                    <Link to={`/?lang=${selected}#solution`}>
                      {t('解决方案')}
                    </Link>
                  </div>
                  <div>
                    <Link to={`/?lang=${selected}#kaiwu`}>{t('BACK 3D')}</Link>
                  </div>
                </div>
                <div>
                  <div>
                    <Link to={`/?lang=${selected}#about`}>{t('关于我们')}</Link>
                  </div>
                  <div>
                    <Link to={`/?lang=${selected}#contact`}>
                      {t('联系我们')}
                    </Link>
                  </div>
                  <div>
                    <Link to={`/explore?lang=${selected}`}>
                      {t('了解更多')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-3">
              <div className="title">{t('联系我们')}</div>
              <div className="email">
                <img src={EmailSvg} />
                <span>Btech@baixin-ai.com</span>
              </div>
              <div className="wechat">
                <img src={QRCode} alt="白心科技" />
                <div>{t('白心科技公众号')}</div>
              </div>
            </div>
          </section>
          <section className="copyright">
            <a
              href="https://beian.miit.gov.cn"
              target="_blank"
              rel="noreferrer"
            >
              浙ICP备2021037638号-1
            </a>
            <span style={{ marginLeft: '10px' }}>
              Copyright © 2022 杭州白心科技有限公司
            </span>
          </section>
        </div>
      </div>
    </div>
  );
};

export default LayoutPC;
