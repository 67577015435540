import React, { useCallback, useEffect, useRef, useState } from 'react';
import './mobile.scss';
import BannerPng from '@/assets/banner-mobile.png';
import Platform1 from '@/assets/platform1.png';
import Platform2 from '@/assets/platform2.png';
import Platform3 from '@/assets/platform3.png';
import Platform4 from '@/assets/platform4.png';

import SloganPng from '@/assets/slogan-mobile.png';
import SlogonEnPng from '@/assets/slogan-mobile-en.png';
import { ReactComponent as CalendarSvg } from '@/assets/calendar.svg';
import { ReactComponent as SmartSvg } from '@/assets/smart.svg';
import { ReactComponent as BoxSvg } from '@/assets/box.svg';
import { ReactComponent as TargetSvg } from '@/assets/target.svg';
import logoBigUrl from '@/assets/logoBig.svg';
import QRCode from '@/assets/QRCode.jpg';
import FollowBgUrl from '@/assets/follow-bg.svg';

import { Carousel, Collapse, Image } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import packing1 from '@/assets/packing1.jpg';
import packing2 from '@/assets/packing2.jpg';
import packing3 from '@/assets/packing3.jpg';
import packing4 from '@/assets/packing4.jpg';
import packing5 from '@/assets/packing5.jpg';
import packing6 from '@/assets/packing6.jpg';
import packing7 from '@/assets/packing7.jpg';
import { useTranslation } from 'react-i18next';
import FlowGif from '@/assets/flow-mobile.gif';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const container = useRef(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [top, setTop] = useState<number>();

  const lang = navigator.language === 'zh-CN' ? 'zh' : 'en';

  useEffect(() => {
    setTop((imgRef.current?.height || 0) / 2);
  }, [imgRef.current]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="home mobile" ref={container}>
      <section className="banner" id="home">
        <div className="slogan">
          <img
            style={{ maxWidth: '100%' }}
            src={lang === 'en' ? SlogonEnPng : SloganPng}
          />
          <div className="text">
            {t(
              '⼤消费+AI，提供端到端从⽣产到销售的供应链服务的数字化解决⽅案'
            )}
          </div>
        </div>
        <img style={{ maxWidth: '100%' }} src={BannerPng} />
      </section>
      <section id="products">
        <div className="features">
          <div className="feature-item">
            <div className="title">
              <CalendarSvg />
              <span>{t('缩短周期')}</span>
            </div>
            <span>{t('为消费品研发节省80%+的时间')}</span>
          </div>
          <div className="feature-item">
            <div className="title">
              <SmartSvg />
              <span>{t('⼤消费+AI')}</span>
            </div>
            <span>{t('深度结合⾏业，AI算法助⼒')}</span>
          </div>
          <div className="feature-item">
            <div className="title">
              <BoxSvg />
              <span>{t('供应链交付')}</span>
            </div>
            <span>{t('从⼯业⽣产到产业数字化服务')}</span>
          </div>
        </div>
        <div className="products">
          <div className="title">
            <div>{t('品牌＆供应商消费⽣态')}</div>
          </div>
          <div className="desc">
            {t(
              '搭建云产品设计，消费数据流通，助⼒品牌与供应商实现线上产品创造，⽀持业务数字化⾼度发展'
            )}
          </div>
          <div className="platforms">
            <Carousel
              centerMode={true}
              infinite={true}
              slidesToShow={1}
              dots={false}
            >
              <div className="item">
                <div className="header">
                  <div className="name">{t('BACK 3D')}</div>
                  <span className="img">
                    <img src={Platform1} style={{ width: 108, height: 108 }} />
                  </span>
                </div>
                <div className="content">
                  <div>
                    {t(
                      '基于供应链所需，提供可⽣产标准3D模型及⽩⼼⾃建创新模型，可快速帮助⽣产⼯⼚定位所需产品模型。'
                    )}
                  </div>
                  <Link className="more" to={`/explore?lang=${lang}`}>
                    {t('了解更多')}
                  </Link>
                </div>
              </div>
              <div className="item">
                <div className="header">
                  <div className="name">{t('乘⻢AI')}</div>
                  <span className="img">
                    <img src={Platform2} style={{ width: 108, height: 108 }} />
                  </span>
                </div>
                <div className="content">
                  <div>
                    {t(
                      '基于品牌商＆供应链所需，⽩⼼专注于AI应⽤层，⾃建AI⾏业数据模型，让AIGC,GPT为客户服务。'
                    )}
                  </div>
                  <Link className="more" to={`/explore?lang=${lang}`}>
                    {t('了解更多')}
                  </Link>
                </div>
              </div>
              <div className="item">
                <div className="header">
                  <span className="name">{t('⽩⼼智能算法')}</span>
                  <span className="img">
                    <img src={Platform3} style={{ width: 108, height: 108 }} />
                  </span>
                </div>
                <div className="content">
                  <div>
                    {t(
                      '独有的BAXIN-DeepPackSynthesize等算法，可根据商户或供应链的商品和产销数据，为客户沉淀为数字化资源，随时调⽤。'
                    )}
                  </div>
                  <Link className="more" to={`/explore?lang=${lang}`}>
                    {t('了解更多')}
                  </Link>
                </div>
              </div>
              <div className="item">
                <div className="header">
                  <span className="name">{t('联和制供应链（BSP）')}</span>
                  <span className="img">
                    <img src={Platform4} style={{ width: 108, height: 108 }} />
                  </span>
                </div>
                <div className="content">
                  <div>
                    {t(
                      '在线完成智能核价与⼯艺资料，直连⽣产，实现⾼效的智能制造产出。'
                    )}
                  </div>
                  <Link className="more" to={`/explore?lang=${lang}`}>
                    {t('了解更多')}
                  </Link>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </section>
      <section className="pains">
        <div className="title">{t('常见应用场景及痛点')}</div>
        <div className="list">
          <Collapse
            accordion={true}
            defaultActiveKey={['1']}
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? <UpOutlined /> : <DownOutlined />
            }
          >
            <Collapse.Panel
              className="item"
              collapsible="header"
              header={
                <div className="item-header">
                  <TargetSvg fill="currentColor" />
                  <span>{t('痛点：产品研发周期⻓')}</span>
                </div>
              }
              key="1"
            >
              {t(
                '新产品开发需要需求⽅与研发⽅反复沟通，⽣产阶段缺少解决⽅案，确认流程冗⻓'
              )}
            </Collapse.Panel>
            <Collapse.Panel
              className="item"
              collapsible="header"
              header={
                <div className="item-header">
                  <TargetSvg fill="currentColor" />
                  <span>{t('痛点：消费企业缺少AI能⼒')}</span>
                </div>
              }
              key="2"
            >
              {t(
                'AI技术⽇新⽉异，当企业在AI能⼒的应⽤落后，企业也将逐渐降低竞争⼒'
              )}
            </Collapse.Panel>
            <Collapse.Panel
              className="item"
              collapsible="header"
              header={
                <div className="item-header">
                  <TargetSvg fill="currentColor" />
                  <span>{t('痛点：商品流转效率低，流量成本变贵')}</span>
                </div>
              }
              key="3"
            >
              {t(
                '商品缺少新渠道⽅式，缺少商品服务⼯具，退货率攀升，盈利困难'
              )}
            </Collapse.Panel>
            <Collapse.Panel
              className="item"
              collapsible="header"
              header={
                <div className="item-header">
                  <TargetSvg fill="currentColor" />
                  <span>{t('痛点：供应链管理效率低')}</span>
                </div>
              }
              key="4"
            >
              {t('以传统⽅式需求驱动效率低下，缺少控质体系，产品质量⽆法保证')}
            </Collapse.Panel>
          </Collapse>
        </div>
      </section>
      <section id="solution" className="solution">
        <div className="title">
          <div>{t('领先、全面、高效的产品解决方案')}</div>
        </div>
        <div className="desc">
          {t(
            '强⼒的AI应⽤落地能⼒，完善、成熟的多维度解决⽅案，帮助品牌商＆供应链轻松快速进⼊超⻋道'
          )}
        </div>
        <div className="content">
          <img src={FlowGif} />
        </div>
      </section>
      <section id="kaiwu" className="kaiwu">
        <div className="title">{t('BACK 3D生成图')}</div>
        <Carousel
          centerMode={true}
          infinite={true}
          slidesToShow={1}
          autoplay
          slidesToScroll={1}
          autoplaySpeed={3500}
          speed={1500}
          dots={false}
        >
          <Image preview={false} src={packing7} />
          <Image preview={false} src={packing5} />
          <Image preview={false} src={packing1} />
          <Image preview={false} src={packing2} />
          <Image preview={false} src={packing3} />
          <Image preview={false} src={packing4} />
          <Image preview={false} src={packing6} />
        </Carousel>
      </section>
      <section id="about" className="about">
        <div className="title">{t('关于我们')}</div>
        <div style={{ position: 'relative' }}>
          <img ref={imgRef} style={{ width: '100%' }} src={logoBigUrl} />
          <div className="desc1">
            <div>
              {t(
                '⽩⼼科技旨在⼈⼯智能(AI)以及⼤数据(Data)领域不断地进⾏技术⾰新,为⾯向未来的消费品牌和消费产品供应商提供AI应⽤产品，帮助完成从⽣产端到销售端整体数字化解决⽅案。'
              )}
            </div>
            <div>
              {t(
                '⽩⼼创始团队来⾃⽹易，字节跳动和⼀线消费品牌，团队有完善的AI、⼤数据、企业服务能⼒和超强的技术落地能⼒。'
              )}
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top,
              bottom: 0,
              left: '-1.4rem',
              right: '-1.4rem',
              background: '#F4F6F9',
              zIndex: -1,
            }}
          />
        </div>
        <div className="title" style={{ marginTop: '4.4rem' }}>
          {t('扫码关注我们')}
        </div>
        <div className="desc2">
          <div style={{ marginBottom: '0.8rem' }}>{t('白心公众号')}</div>
          <div style={{ marginBottom: '1.8rem' }}>
            <img width={84} src={QRCode} alt="白心科技" />
          </div>
          <div style={{ marginBottom: '0.8rem' }}>{t('商务合作')}</div>
          <div>Btech@baixin-ai.com</div>
          <div className="bg">
            <img src={FollowBgUrl} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
