import React, { useCallback, useEffect, useState } from 'react';
import './mobile.scss';
import { Button, Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ReactComponent as UserSvg } from '@/assets/user.svg';
import { ReactComponent as AddressBookSvg } from '@/assets/addressBook.svg';
import { ReactComponent as CompanySvg } from '@/assets/company.svg';
import { post } from '@/services/request';
import { useTranslation } from 'react-i18next';

const ExploreMobile: React.FC = () => {
  const { t } = useTranslation();
  const [form] = useForm<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const submit = useCallback(async () => {
    await form.validateFields();
    const data = form.getFieldsValue();
    setLoading(true);
    post('/official/moreInfo', data)
      .then(() => {
        setLoading(false);
        message.info('提交成功，我们会尽快与您联系！');
      })
      .catch((e) => {
        setLoading(false);
        message.error(e.message || e);
      });
  }, [form]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="explore mobile" id="explore">
      <div className="spliter" />
      <div className="title">
        <span>{t('了解更多')}</span>
      </div>
      <div className="form">
        <Form form={form}>
          <Form.Item
            name="name"
            label={t('联系姓名')}
            rules={[
              {
                required: true,
                message: t('请填写本人姓名，最少2个字符'),
                type: 'string',
                min: 2,
              },
            ]}
          >
            <Input placeholder={t('请填写本人姓名')} prefix={<UserSvg />} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t('联系方式')}
            rules={[
              {
                type: 'string',
                required: true,
                min: 11,
                max: 11,
                pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                message: t('请填写正确的联系方式'),
              },
            ]}
          >
            <Input
              placeholder={t('请填写联系方式')}
              prefix={<AddressBookSvg />}
            />
          </Form.Item>
          <Form.Item
            name="company"
            label={t('公司昵称')}
            rules={[
              {
                required: true,
                min: 4,
                message: t('请填写公司昵称，最少4个字符'),
              },
            ]}
          >
            <Input
              required
              placeholder={t('请填写公司昵称')}
              prefix={<CompanySvg />}
            />
          </Form.Item>
          <Form.Item
            name="needs"
            label={t('需求描述')}
            // rules={[
            //   {
            //     required: true,
            //     message: t('请简单描述你的需求'),
            //     max: 100,
            //   },
            // ]}
          >
            <Input.TextArea
              rows={4}
              name="needs"
              placeholder={t('请简单描述你的需求（不超过100字）')}
            />
          </Form.Item>
          <Button
            loading={loading}
            className="submit"
            type="primary"
            onClick={submit}
          >
            {t('立即提交')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ExploreMobile;
