import React, { useCallback, useEffect, useRef } from 'react';
import './pc.scss';
import qs from 'querystring';
import { Carousel, Image } from 'antd';
import debounce from 'lodash/debounce';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import BannerPng from '@/assets/pc-banner.png';
import BannerTitleZh from '@/assets/pc-banner-title-zh.png';
import BannerTitleEn from '@/assets/pc-banner-title-en.png';
import Platform1 from '@/assets/platform1.png';
import Platform2 from '@/assets/platform2.png';
import Platform3 from '@/assets/platform3.png';
import Platform4 from '@/assets/platform4.png';
import { ReactComponent as PainPoint1Svg } from '@/assets/painPoint1.svg';
import { ReactComponent as PainPoint2Svg } from '@/assets/painPoint2.svg';
import { ReactComponent as PainPoint3Svg } from '@/assets/painPoint3.svg';
import { ReactComponent as PainPoint4Svg } from '@/assets/painPoint4.svg';
import { ReactComponent as SolutionSvg } from '@/assets/solution.svg';
import FlowGif from '@/assets/flow.gif';

import CompanyPhoto1 from '@/assets/company-photo1.png';
import CompanyPhoto2 from '@/assets/company-photo2.png';
import CompanyPhoto3 from '@/assets/company-photo3.png';
import CompanyPhoto4 from '@/assets/company-photo4.png';

import packing1 from '@/assets/packing1.jpg';
import packing2 from '@/assets/packing2.jpg';
import packing3 from '@/assets/packing3.jpg';
import packing4 from '@/assets/packing4.jpg';
import packing5 from '@/assets/packing5.jpg';
import packing6 from '@/assets/packing6.jpg';
import packing7 from '@/assets/packing7.jpg';
import TopPng from '@/assets/top.png';

const HomePC: React.FC = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const container = useRef(null);
  const language = i18n.language;

  const lang = (qs.parse(location.search.slice(1))?.lang || 'zh') as string;

  const scrollToAnchor = useCallback((hash: string) => {
    if (hash) {
      const offsetTop = (document.querySelector(hash) as any)?.offsetTop;
      const appEle = document.querySelector('html');
      appEle?.scrollTo({
        top: offsetTop - 66,
        behavior: 'smooth',
      });
    }
  }, []);

  const onScroll = debounce(
    useCallback((e) => {
      const navA = document.querySelectorAll('.nav a');
      navA.forEach((item) => (item.className = ''));
      const scrollTop = document.documentElement.scrollTop;
      const sections = document.querySelectorAll('section[id]');
      for (let item of Array.from(sections)) {
        const top = (item as any).offsetTop - 66;
        const height = (item as any).clientHeight;
        console.log('scrollTop', scrollTop, 'top', top);
        if (scrollTop >= top - 1 && scrollTop <= top - 1 + height) {
          const target = Array.from(navA).find(
            (_item) => (_item as any).dataset.hash === item.id
          );
          if (target) {
            target.className = 'active';
          }
          return;
        }
      }
    }, []),
    100
  );

  useEffect(() => {
    scrollToAnchor(location.hash);
  }, [location.hash]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="home pc" ref={container}>
      <section className="banner" id="home">
        <div className="background-img">
          <img
            style={{ height: '100%', width: '100%' }}
            src={BannerPng}
          />
        </div>
        <div className="slogan-bg"></div>
        <div className="content-wrapper">
          <div className="slogan">
            <div className="title-img" style={lang === 'en' ? { width:  663, height: 112 } : { width: 500, height: 120 }}>
              <img
                style={{ width: '100%', height: '100%' }}
                src={lang === 'en' ? BannerTitleEn : BannerTitleZh}
              />
            </div>
            <div className="text">
              {t(
                '⼤消费+AI，提供端到端从⽣产到销售的供应链服务的数字化解决⽅案'
              )}
            </div>
          </div>
        </div>
      </section>
      <section id="products" className="products">
        <div className="content-1 content-wrapper">
          <div>
            <span>{t('缩短周期')}</span>
            <span>{t('为消费品研发节省80%+的时间')}</span>
          </div>
          <div>
            <span>{t('⼤消费+AI')}</span>
            <span>{t('深度结合⾏业，AI算法助⼒')}</span>
          </div>
          <div>
            <span>{t('供应链交付')}</span>
            <span>{t('从⼯业⽣产到产业数字化服务')}</span>
          </div>
        </div>
        <div className="content-2">
          <div className="content-wrapper">
            <div className="title">
              {t('品牌＆供应商消费⽣态')}
            </div>
            <div className="desc">
              {t(
                '搭建云产品设计，消费数据流通，助⼒品牌与供应商实现线上产品创造，⽀持业务数字化⾼度发展'
              )}
            </div>
            <div className={cx('platforms', {
              'platforms-en': language === 'en'
            })}>
              <div className="item">
                <div className="header">
                  <div className="name">{t('BACK 3D')}</div>
                  <span className="img">
                    <img src={Platform1} style={{ width: 108, height: 108 }} />
                  </span>
                </div>
                <div className="content">
                  <div>
                    {t(
                      '基于供应链所需，提供可⽣产标准3D模型及⽩⼼⾃建创新模型，可快速帮助⽣产⼯⼚定位所需产品模型。'
                    )}
                  </div>
                  <Link className="more" to={`/explore?lang=${lang}`}>
                    {t('了解更多')}
                  </Link>
                </div>
              </div>
              <div className="item">
                <div className="header">
                  <div className="name">{t('乘⻢AI')}</div>
                  <span className="img">
                    <img src={Platform2} style={{ width: 108, height: 108 }} />
                  </span>
                </div>
                <div className="content">
                  <div>
                    {t(
                      '基于品牌商＆供应链所需，⽩⼼专注于AI应⽤层，⾃建AI⾏业数据模型，让AIGC,GPT为客户服务。'
                    )}
                  </div>
                  <Link className="more" to={`/explore?lang=${lang}`}>
                    {t('了解更多')}
                  </Link>
                </div>
              </div>
              <div className="item">
                <div className="header">
                  <span className="name">{t('⽩⼼智能算法')}</span>
                  <span className="img">
                    <img src={Platform3} style={{ width: 108, height: 108 }} />
                  </span>
                </div>
                <div className="content">
                  <div>
                    {t(
                      '独有的BAXIN-DeepPackSynthesize等算法，可根据商户或供应链的商品和产销数据，为客户沉淀为数字化资源，随时调⽤。'
                    )}
                  </div>
                  <Link className="more" to={`/explore?lang=${lang}`}>
                    {t('了解更多')}
                  </Link>
                </div>
              </div>
              <div className="item">
                <div className="header">
                  <span className="name">{t('联和制供应链（BSP）')}</span>
                  <span className="img">
                    <img src={Platform4} style={{ width: 108, height: 108 }} />
                  </span>
                </div>
                <div className="content">
                  <div>
                    {t(
                      '在线完成智能核价与⼯艺资料，直连⽣产，实现⾼效的智能制造产出。'
                    )}
                  </div>
                  <Link className="more" to={`/explore?lang=${lang}`}>
                    {t('了解更多')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="solution" className="solution">
        <div className="content-wrapper">
          <div className="header">
            <div>{t('领先、全⾯、⾼效的产品解决⽅案')}</div>
            <div>
              {t(
                '强⼒的AI应⽤落地能⼒，完善、成熟的多维度解决⽅案，帮助品牌商＆供应链轻松快速进⼊超⻋道'
              )}
            </div>
          </div>
          <div className="content">
            <div className="left">
              <div className="item">
                <div className="title">
                  <span className="img">
                    <PainPoint1Svg />
                  </span>
                  <span>{t('痛点：产品研发周期⻓')}</span>
                </div>
                <div className="detail">
                  {t(
                    '新产品开发需要需求⽅与研发⽅反复沟通，⽣产阶段缺少解决⽅案，确认流程冗⻓'
                  )}
                </div>
              </div>
              <div className="spliter" />
              <div className="item">
                <div className="title">
                  <span className="img">
                    <PainPoint2Svg />
                  </span>
                  <span>{t('痛点：消费企业缺少AI能⼒')}</span>
                </div>
                <div className="detail">
                  {t(
                    'AI技术⽇新⽉异，当企业在AI能⼒的应⽤落后，企业也将逐渐降低竞争⼒'
                  )}
                </div>
              </div>
              <div className="spliter" />
              <div className="item">
                <div className="title">
                  <span className="img">
                    <PainPoint3Svg />
                  </span>
                  <span>{t('痛点：商品流转效率低，流量成本变贵')}</span>
                </div>
                <div className="detail">
                  {t(
                    '商品缺少新渠道⽅式，缺少商品服务⼯具，退货率攀升，盈利困难'
                  )}
                </div>
              </div>
              <div className="spliter" />
              <div className="item">
                <div className="title">
                  <span className="img">
                    <PainPoint4Svg />
                  </span>
                  <span>{t('痛点：供应链管理效率低')}</span>
                </div>
                <div className="detail">
                  {t('以传统⽅式需求驱动效率低下，缺少控质体系，产品质量⽆法保证')}
                </div>
              </div>
            </div>
            <div className="right">
              <div className="title">
                <span className="img">
                  <SolutionSvg />
                </span>
                <span>{t('解决⽅案图：')}</span>
              </div>
              <div className="detail">
                <img src={FlowGif} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="kaiwu" className="kaiwu">
        <div className="content-wrapper">
          <div className="title">{t('BACK 3D生成图')}</div>
          <Carousel
            autoplay
            slidesToShow={3}
            slidesToScroll={1}
            autoplaySpeed={3500}
            speed={1500}
            dots={{
              className: 'dots',
            }}
          >
            <Image width={450} preview={false} src={packing7} />
            <Image width={450} preview={false} src={packing5} />
            <Image width={450} preview={false} src={packing1} />
            <Image width={450} preview={false} src={packing2} />
            <Image width={450} preview={false} src={packing3} />
            <Image width={450} preview={false} src={packing4} />
            <Image width={450} preview={false} src={packing6} />
          </Carousel>
        </div>
      </section>
      <section id="about" className="about">
        <div className="content-wrapper">
          <div className="header">
            <div>{t('关于我们')}</div>
            <div>
              {t(
                '⽩⼼科技旨在⼈⼯智能(AI)以及⼤数据(Data)领域不断地进⾏技术⾰新,为⾯向未来的消费品牌和消费产品供应商提供AI应⽤产品，帮助完成从⽣产端到销售端整体数字化解决⽅案。'
              )}
              {t(
                '⽩⼼创始团队来⾃⽹易，字节跳动和⼀线消费品牌，团队有完善的AI、⼤数据、企业服务能⼒和超强的技术落地能⼒。'
              )}
            </div>
          </div>
          <div className="detail">
            <img src={CompanyPhoto1} style={{ width: 604, height: 376 }} />
            <img src={CompanyPhoto2} style={{ width: 334, height: 376, marginLeft: 24 }} />
            <div style={{ marginLeft: 24 }}>
              <img src={CompanyPhoto3} style={{ width: 334, height: 176 }} />
              <img src={CompanyPhoto4} style={{ width: 334, height: 176, marginTop: 24 }} />
            </div>
          </div>
        </div>
        <div id="contact" className="more">
          <div className="title">{t('欢迎了解体验更多白心科技产品')}</div>
          <Link className="btn" to={`/explore?lang=${lang}`}>
            {t('了解更多')}
          </Link>
        </div>
      </section>
      <section className="top" title={t('返回顶部')}>
        {/* <Link to={`/${location.search}#home`}> */}
        <img
          src={TopPng}
          style={{ width: '72px' }}
          onClick={() => scrollToAnchor('home')}
        />
        {/* </Link> */}
      </section>
    </div>
  );
};

export default HomePC;
