import axios, { AxiosRequestConfig } from 'axios';
import { message } from 'antd';
import { Method, ResponseType, RequestOptions } from '@/types/request';

const URL_PREFIX = '/home/xhr';

export const requst: <T>(
  options: RequestOptions
) => Promise<ResponseType<T>['data']> = (options) => {
  const { url, method = 'GET', data = {}, headers = {} } = options;
  const config: AxiosRequestConfig = {
    url,
    method,
    headers,
    timeout: 60000,
    baseURL: URL_PREFIX,
  };
  if (method === Method.GET) {
    config.params = data;
  } else {
    config.headers = {
      ...headers,
      'Content-Type': 'application/json',
    };
    config.data = data;
  }
  return new Promise((resolve, reject) => {
    return axios
      .request<ResponseType>(config)
      .then((res) => {
        const { code, data } = res.data;
        if (code >= 200 && code < 299) {
          resolve(data);
        } else {
          reject(res.data);
        }
      })
      .catch((e) => {
        reject(e);
        message.error(e.message);
      });
  });
};

export const get: <T>(
  url: string,
  data?: any
) => Promise<ResponseType<T>['data']> = (url, data?) => {
  return requst({
    url,
    data,
    method: Method.GET,
  });
};

export const post: <T>(
  url: string,
  data?: any
) => Promise<ResponseType<T>['data']> = (url, data) => {
  return requst({
    url,
    data,
    method: Method.POST,
  });
};

export const put: <T>(
  url: string,
  data?: any
) => Promise<ResponseType<T>['data']> = (url, data) => {
  return requst({
    url,
    data,
    method: Method.PUT,
  });
};

// export const delete = (url: string, data: any) => {
//   return requst({
//     url,
//     data,
//     method: Method.DELETE,
//   });
// };
