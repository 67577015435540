import React, { useEffect, useState } from 'react';
import './App.scss';
import LayoutPC from './pages/layout/LayoutPC';
import LayoutMobile from './pages/layout/LayoutMobile';

function App() {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const ua = navigator.userAgent.toLowerCase();
  console.log(ua);
  const mobileAgents = [
    'iphone',
    'ipad',
    'ipod',
    'android',
    'linux',
    'windows phone',
  ]; // 所有可能是移动端设备的字段

  useEffect(() => {
    for (let agent of mobileAgents) {
      if (ua.indexOf(agent) > -1) {
        setIsMobile(true);
        return;
      }
    }
  }, []);

  return (
    <div className="App">{isMobile ? <LayoutMobile /> : <LayoutPC />}</div>
  );
}

export default App;
