import { AxiosRequestHeaders } from 'axios';

export interface ResponseType<T = any> {
  code: number;
  message?: string;
  data: {
    result: T;
    pagination?: Pagination;
  };
}

export enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface Pagination {
  page: number;
  size: number;
  total: number;
}

export interface RequestOptions {
  url: string;
  method?: Method;
  data: any;
  headers?: AxiosRequestHeaders;
}