import React, { useCallback, useEffect, useState } from 'react';
import './mobile.scss';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import LogoMobile from '@/assets/logo-mobile.svg';
import LogoMobileEnPng from '@/assets/logo-mobile-en.png';
import { ReactComponent as CloseSvg } from '@/assets/close.svg';
import { ReactComponent as WriteSvg } from '@/assets/write.svg';
import HomeMobile from '../home/HomeMobile';
import ExploreMobile from '../explore/ExploreMobile';

const LayoutMobile: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState<string>('zh');
  const [exploreHidden, setExploreHidden] = useState<boolean>(
    localStorage.getItem('exploreHidden') === 'true'
  );

  const hideExplore = useCallback(() => {
    setExploreHidden(true);
    localStorage.setItem('exploreHidden', 'true');
  }, []);

  useEffect(() => {
    const _lang = navigator.language === 'zh-CN' ? 'zh' : 'en';
    setLang(_lang);
    i18n.changeLanguage(_lang);
  }, [navigator.language]);

  return (
    <div className="layout mobile">
      <header className="header">
        <div className="logo">
          <Link to={`/?lang=${lang}`}>
            <img
              height="26"
              src={lang === 'en' ? LogoMobileEnPng : LogoMobile}
            />
          </Link>
        </div>
        <div className="tools">
          <Link className="contact-btn" to={`/explore`}>
            {t('联系我们')}
          </Link>
        </div>
      </header>
      <div className="center">
        <Switch>
          <Route path={'/'} exact={true} component={HomeMobile} />
          <Route path={'/explore'} exact={true} component={ExploreMobile} />
          <Redirect to={'/'} />
        </Switch>
      </div>
      <footer className="footer">
        <Link className="link" to={'/'}>
          {t('官网首页')}
        </Link>
        <Link className="link" to={'/'}>
          {t('关于我们')}
        </Link>
        <Link className="link" to={'/'}>
          {t('联系我们')}
        </Link>
        {/* <div className="info">{t('商务合作')}：zeyiwang@baixin-ai.com</div>
        <div className="info">{t('工作时间')}：10:00-19:00</div> */}
        <div className="phone">{t('商务合作')}：Btech@baixin-ai.com</div>
        <div className="cr">Copyright © 2022 杭州白心科技有限公司</div>
        <div className="cr" style={{ paddingBottom: '24px' }}>
          <a href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer">
            浙ICP备2021037638号-1
          </a>
        </div>
        {!exploreHidden && (
          <div className="explore">
            <Link className="explore-link" to={'/explore'}>
              {t('立即了解')}
            </Link>
            <CloseSvg className="close" onClick={() => hideExplore()} />
            <WriteSvg className="write" />
            <div
              style={{
                padding: '10px 0',
                display: 'inline-block',
                verticalAlign: 'text-bottom',
              }}
            >
              <div className="txt">{t('了解更多')}</div>
              <div className="txt">{t('请告诉我需要什么帮助？')}</div>
            </div>
          </div>
        )}
      </footer>
    </div>
  );
};

export default LayoutMobile;
